@import '../../Sass/GlobalMixins.module.scss';
@import '../../Sass/GlobalFunctions.module.scss';

.Navbar {
    display: flex;
    min-height: 50px;
    width: 100%;
    min-width: 110%;
    text-align: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #1d2029;
    padding-left: 0.1%;
    -moz-box-shadow: 5px 15px 5px 0px rgba(51, 51, 51, 0.356);
    -webkit-box-shadow: 5px 15px 5px 0px rgba(51, 51, 51, 0.356);
    box-shadow: 5px 15px 5px 0px rgba(51, 51, 51, 0.356);
    border: 2px;
    margin-bottom: 10%;
}

.Sticky {
    bottom: inherit;
    left: -5%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.DesktopOnly {
    display: none;
    
}

.ErrorBox {
    background-color: f_color(secondary);
    color: rgb(125, 0, 0);
    font-weight: bold;
}

@include desktop
{
    .DesktopOnly {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-right: 5%;
        padding-left: 5%;
    }
}