@import './Sass/GlobalFunctions.module.scss';

.App {
  min-height: 100vh;
  background-color: f_color(secondary);
  overflow-x: hidden;
  width: 100%;
}

section {
  background-color: f_color(primary);
}

section:nth-of-type(even) {
  background-color: f_color(secondary);
}