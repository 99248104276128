@import '../../Sass/GlobalMixins.module.scss';

.Projects {
    align-items: center;
    display: flex;
    line-height: 18pt;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    width: 100%;

    .ProjectsContentContainer {
        color: #fff;
        display: flex;
        margin-top: 5%;
        width: 70%;
        justify-content: center;
        flex-direction: column;
        h1 {
            font-size: 2.5em;
            margin-top: 5%;
            text-align: center;
            width: auto;
            padding: 20px 0;
            border-bottom: solid black 2px;
            font-weight: 800;
        }
        min-height: 100%;

        .ProjectListing {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
        }
    }
}

@include mobile {
    .Projects {
        color: #fff;
        align-items: center;
        display: flex;
        line-height: 18pt;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        width: 100%;
    
        .ProjectsContentContainer {
            display: flex;
            margin-top: 20%;
            width: 95%;
            justify-content: center;
            h1 {
                font-size: 2.5em;
                margin-top: 5%;
                text-align: center;
                width: auto;
                padding: 20px 0;
                border-bottom: solid black 2px;
                font-weight: 800;
            }
            min-height: 100%;
        }
    }
}