@import '../../../../Sass/GlobalFunctions.module.scss';
@import '../../../../Sass/GlobalMixins.module.scss';


.NavigationItem
{
    display: flex;
    width: auto;
    min-width: 80px;
    margin: 2%;
    align-items: center;

    img {
        object-fit: contain;
        width: 32px;
        height: 32px;
        max-height: 32px;
    }
    

    a {
        width: 100%;
        background-color: f_color(fifth);
        height: max-content;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        color: f_color(fourth);
        padding: 10%;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        box-shadow: 2px 2px black;
        transition: 0.5s;
    }

    a:hover {
        transform: scale(1.2,1.2);
        background-color: transparentize($color: f_color(fifth), $amount: 0.2);
    }
}

@include desktop
{
    .NavigationItem {
        display: flex;
        width: fit-content;
        min-width: 140px;
        max-width: 160px;
        margin: 0.5%;
    
        a {
            width: 100%;
            background-color: f_color(fifth);
            height: max-content;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            color: f_color(fourth);
            padding: 10%;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
        }
        
        img {
            object-fit: contain;
        }
    }
}