@import '../../Sass/GlobalFunctions.module.scss';
@import '../../Sass/GlobalMixins.module.scss';

.Contact {
    color: #fff;
    align-items: center;
    display: flex;
    line-height: 18pt;
    flex-direction: column;
    justify-content: flex-start;
    min-height: auto;
    width: 100%;

    .TriangleTop {
        margin-top: 2.5%;
        width: 98%;
        height: 50%;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 25px solid f_color(primary);
        cursor: pointer;
    }
    

    .ContactContentContainer {
        display: flex;
        width: 60%;
        justify-content: center;
        flex-direction: column;
        h1 {
            font-size: 2.5em;
            color: rgb(63, 63, 63);
            margin-top: 5%;
            text-align: center;
            width: auto;
            padding: 20px 0;
            border-bottom: solid black 2px;
            font-weight: 800;
        }
        min-height: 100%;

        .ContactForm {
            width: 60%;
            margin: 0 auto;
            padding: 2%;
            form {
                display: block;
                input {
                    display: block;
                    width: 100%;
                    line-height: 1.2em;
                    font-size: 1.2em;
                    margin: 2% auto;
                    background-color: #1e242c;
                    color: white;
                }

                input::placeholder {
                    color: transparentize($color: white, $amount: 0.05);
                }

                textarea::placeholder {
                    color: transparentize($color: white, $amount: 0.05);
                    font-size: 1.2em;
                }

                textarea {
                    width: 100%;
                    font-size: 1.2em;
                    color:white;
                    line-height: 1.2em;
                    background-color: #1e242c;
                    min-height: 150px;
                }

                button {
                    width: 100%;
                    display: block;
                }

                .ButtonAndFeedbackContact {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    .ButtonContactDiv {
                        background-color: #11344d;
                        color: #fff;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 1%;
                        box-shadow: -2px 1px #000028;
                        cursor: pointer;
                        transition: transform 0.5s, background-color 0.5s, box-shadow 0.5s;
                        button {
                            background-color: #11344d;
                            padding: 5%;
                            font-size: 1.2em;
                            transition: 0.5s;
                            border: 0;
                            color: white;
                            cursor: pointer;
                        }
                    }

                    .ButtonContactDiv:hover {
                        transform: scale(1.2,1.2);
                        background-color: #000028;
                        box-shadow: -3px 2px #a8a8e6;

                        button {
                            background-color: #000028;
                        }
                    }

                    .FeedbackDiv {
                        flex-basis: 90%;
                        display: flex;
                        align-items: center;
                        font-size: 1.2em;
                        vertical-align: middle;
                        color: black;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Contact {
        color: #fff;
        align-items: center;
        display: flex;
        line-height: 18pt;
        flex-direction: column;
        justify-content: flex-start;
        min-height: auto;
        width: 100%;
    
        .ContactContentContainer {
            display: flex;
            width: 95%;
            justify-content: center;
            margin-top: 5%;
            h1 {
                font-size: 2.5em;
                margin-top: 5%;
                text-align: center;
                width: auto;
                padding: 20px 0;
                border-bottom: solid black 2px;
                font-weight: 800;
            }
            min-height: 100%;

            .ContactForm {
                width: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                justify-items: center;
                margin: 0 auto;
                padding: 2%;
                form {
                    display: block;
                    padding-right: 5%;
                    input {
                        display: block;
                        width: 100%;
                        line-height: 1.2em;
                        font-size: 1.2em;
                        background-color: #1e242c;
                        color: white;
                    }
    
                    input::placeholder {
                        color: transparentize($color: white, $amount: 0.05);
                    }
    
                    textarea::placeholder {
                        color: transparentize($color: white, $amount: 0.05);
                        font-size: 1.2em;
                    }
    
                    textarea {
                        width: 100%;
                        font-size: 1.2em;
                        color:white;
                        line-height: 1.2em;
                        background-color: #1e242c;
                        min-height: 150px;
                    }
    
                    button {
                        width: 100%;
                        display: block;
                    }
    
                    .ButtonAndFeedbackContact {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
    
                        .ButtonContactDiv {
    
                            button {
                                background-color: transparent;
                                color: white;
                                border: 2px solid white;
                                padding: 5%;
                                font-size: 1em;
                            }
                        }
    
                        .FeedbackDiv {
                            flex-basis: 90%;
                            display: flex;
                            align-items: center;
                            font-size: 1.2em;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}