@import '../../../Sass/GlobalMixins.module.scss';
@import '../../../Sass/GlobalFunctions.module.scss';

.SideDrawer {
    position:fixed;
    width: 280px;
    max-width: 75%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #1d2029;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
}

.NavItems {
    min-width: 100%;
    min-height: 100%;
}

@include desktop
{
    .SideDrawer{
        display:none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}