@import '../../Sass/GlobalFunctions.module.scss';
@import '../../Sass/GlobalMixins.module.scss';

.About {
    color: rgb(63, 63, 63);
    padding: 0 10px;
    align-items: center;
    display: flex;
    line-height: 18pt;
    flex-direction: column;
    position: relative;
    min-height: 120vh;

    .AboutContentContainer {
        display: flex;
        width: 60%;
        justify-content: center;

        .ExtraMargin
        {
            margin-top: 10%;
        }
        .AboutContent {
            text-align: center;
            h1 {
                font-size: 2.5em;
                margin-top: 5%;
                text-align: center;
                width: auto;
                padding: 20px 0;
                border-bottom: solid black 2px;
                font-weight: 800;
            }
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            .AboutText {
                width: 60%;

                span {
                    font-weight: 600;
                }

                .Colorized {
                    color: f_color(third);
                }

                .ColorizedAlt {
                    color: f_color(fourth);
                }
            }
        }
    }

    .SkillSeksjon {
        margin: 0 auto;
        display: flex;
        position: relative;
        max-height: 5%;
        justify-content: center;
        margin-top: 25%;
        -webkit-transform-style: preserve-3d;
        -webkit-animation: spin 10s linear infinite;
        -moz-transform-style: preserve-3d;
        -moz-animation: spin 10s linear infinite;
        -ms-transform-style: preserve-3d;
        -ms-animation: spin 10s linear infinite;
        transform-style: preserve-3d;
        animation: spin 10s linear infinite;
        @keyframes spin {
            0% {
                -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            }
            100% {
                -webkit-transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
            }
        }

        @keyframes spin {
            0% {
                -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            }
            100% {
                -moz-transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            }
            100% {
                transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
            }
        }
        
        div {
            position: absolute;
            background-color: rgba(230, 230, 230, 0.445);
            max-width: 300px;
            max-height: 300px;
            content: url(/reactLogo.png);
        }

        div:first-child {
            border-color: transparent transparent transparent rgba(50,50,50,.6);
            -webkit-transform: translateZ(73px);
            -moz-transform: translateZ(73px);
            -ms-transform: translateZ(73px);
            transform: translateZ(73px);
            content: url(/angularLogo.png);
        }

        div:nth-child(2) {
            border-color: transparent transparent transparent rgba(50,50,50,.6);
            -webkit-transform: rotateY(90deg) rotateZ(0) translateZ(73px);
            -moz-transform: rotateY(90deg) rotateZ(0) translateZ(73px);
            -ms-transform: rotateY(90deg) rotateZ(0) translateZ(73px);
            transform: rotateY(90deg) rotateZ(0) translateZ(73px);
            content: url(/htmlLogo.png);
        }

        div:nth-child(3) {
            border-color: transparent transparent transparent rgba(50,50,50,.9);
            -webkit-transform: rotateY(180deg) translateZ(73px);
            -moz-transform: rotateY(180deg) translateZ(73px);
            -ms-transform: rotateY(180deg) translateZ(73px);
            transform: rotateY(180deg) translateZ(73px);
            content: url(/dotnetcoreLogo.png);
        }
        div:nth-child(4) {
            border-color: transparent transparent transparent rgba(50,50,50,.8);
            -webkit-transform: rotateY(-90deg) translateZ(73px);
            -moz-transform: rotateY(-90deg) translateZ(73px);
            -ms-transform: rotateY(-90deg) translateZ(73px);
            transform: rotateY(-90deg) translateZ(73px);
            content: url(/nodeLogo.png);
        }
        div:nth-child(5) {
            border-color: transparent transparent transparent rgba(50,50,50,.8);
            -webkit-transform: rotateX(90deg) translateZ(73px);
            -moz-transform: rotateX(90deg) translateZ(73px);
            -ms-transform: rotateX(90deg) translateZ(73px);
            transform: rotateX(90deg) translateZ(73px);
        }
        div:nth-child(6) {
            border-color: transparent transparent transparent rgba(50,50,50,.8);
            -webkit-transform: rotateX(-90deg) translateZ(73px);
            -moz-transform: rotateX(-90deg) translateZ(73px);
            -ms-transform: rotateX(-90deg) translateZ(73px);
            transform: rotateX(-90deg) translateZ(73px);
            content: url(/sassLogo.png);
        }
    }
}

@include mobile {
    .About {
        color: rgb(63, 63, 63);
        padding: 0 10px;
        align-items: center;
        display: flex;
        line-height: 18pt;
        flex-direction: column;
        position: relative;
        min-height: 150vh;
    
        .AboutContentContainer {
            display: flex;
            width: 100%;
            justify-content: center;
            .ExtraMargin
            {
                margin-top: 20%;
            }
    
            .AboutContent {
                text-align: center;
                h1 {
                    font-size: 2.5em;
                    margin-top: 5%;
                    text-align: center;
                    width: auto;
                    padding: 20px 0;
                    border-bottom: solid black 2px;
                    font-weight: 800;
                }
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                .AboutText {
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .SkillSeksjon {
            margin: 0 auto;
            display: flex;
            position: relative;
            max-height: 5%;
            justify-content: center;
            margin-top: 25%;
            -webkit-transform-style: preserve-3d;
            -webkit-animation: spin 10s linear infinite;
            -moz-transform-style: preserve-3d;
            -moz-animation: spin 10s linear infinite;
            -ms-transform-style: preserve-3d;
            -ms-animation: spin 10s linear infinite;
            transform-style: preserve-3d;
            animation: spin 10s linear infinite;
            @keyframes spin {
                0% {
                    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                }
                100% {
                    -webkit-transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
                }
            }
    
            @keyframes spin {
                0% {
                    -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                }
                100% {
                    -moz-transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
                }
            }
    
            @keyframes spin {
                0% {
                    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                }
                100% {
                    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
                }
            }
            
            div {
                position: absolute;
                background-color: rgba(230, 230, 230, 0.445);
                max-width: 75px;
                max-height: 75px;
                content: url(/reactLogo.png);
            }
    
            div:first-child {
                border-color: transparent transparent transparent rgba(50,50,50,.6);
                -webkit-transform: translateZ(36.6px);
                -moz-transform: translateZ(36.6px);
                -ms-transform: translateZ(36.6px);
                transform: translateZ(36.6px);
            }
    
            div:nth-child(2) {
                border-color: transparent transparent transparent rgba(50,50,50,.6);
                -webkit-transform: rotateY(90deg) rotateZ(0) translateZ(36.6px);
                -moz-transform: rotateY(90deg) rotateZ(0) translateZ(36.6px);
                -ms-transform: rotateY(90deg) rotateZ(0) translateZ(36.6px);
                transform: rotateY(90deg) rotateZ(0) translateZ(36.6px);
            }
    
            div:nth-child(3) {
                border-color: transparent transparent transparent rgba(50,50,50,.9);
                -webkit-transform: rotateY(180deg) translateZ(36.6px);
                -moz-transform: rotateY(180deg) translateZ(36.6px);
                -ms-transform: rotateY(180deg) translateZ(36.6px);
                transform: rotateY(180deg) translateZ(36.6px);
            }
            div:nth-child(4) {
                border-color: transparent transparent transparent rgba(50,50,50,.8);
                -webkit-transform: rotateY(-90deg) translateZ(36.6px);
                -moz-transform: rotateY(-90deg) translateZ(36.6px);
                -ms-transform: rotateY(-90deg) translateZ(36.6px);
                transform: rotateY(-90deg) translateZ(36.6px);
            }
            div:nth-child(5) {
                border-color: transparent transparent transparent rgba(50,50,50,.8);
                -webkit-transform: rotateX(90deg) translateZ(36.6px);
                -moz-transform: rotateX(90deg) translateZ(36.6px);
                -ms-transform: rotateX(90deg) translateZ(36.6px);
                transform: rotateX(90deg) translateZ(36.6px);
            }
            div:nth-child(6) {
                border-color: transparent transparent transparent rgba(50,50,50,.8);
                -webkit-transform: rotateX(-90deg) translateZ(36.6px);
                -moz-transform: rotateX(-90deg) translateZ(36.6px);
                -ms-transform: rotateX(-90deg) translateZ(36.6px);
                transform: rotateX(-90deg) translateZ(36.6px);
            }
        }
    }
}