@import '../../../Sass/GlobalFunctions.module.scss';

.Project {
    min-width: 360px;
    max-width: 360px;
    max-height: 360px;
    width: 360px;
    position: relative;
    display: flex;
    background-color: f_color(primary);
    justify-content: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 2px 4px black;
    align-content: center;
    margin: 2%;
    .Image {
        min-height: 100%;
        min-width: 100%;
        opacity: 1;
        transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -webkit-transition: opacity .5s ease-in-out;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .Faded {
        opacity: 0.2;
    }
    .MoreInfo {
        z-index: 10;
        min-height: 100%;
        min-width: 100%;
        max-height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        .Label {
            min-height: 5%;
            height: 5%;
            margin-bottom: 2%;
            font-size: 1.5em;
            text-align: center;
            min-width: 100%;
            color: white;
        }
        .TagContainer {
            min-height: 93%;
            height: 93%;
            display: flex;
            flex-wrap: wrap;
            min-width: 100%;
            div {
            padding: 1%;
            background-color: #cecece;
            margin: 1%;
            color: black;
            font-weight: 800;
            box-shadow: 2px 2px black;
            }
            .Deletebutton {
                justify-self: center;
                button {
                    background-color: #11344d;
                    color: #fff;
                    font-weight: 700;
                    text-transform: uppercase;
                    padding: 1%;
                    border-radius: 5%;
                    box-shadow: -2px 1px #000028;
                    cursor: pointer;
                    transition: transform 0.5s, background-color 0.5s, box-shadow 0.5s;
                }
            }
        }
    }

    .Invisible {
        display: none;
    }
}