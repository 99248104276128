@import '../../../../Sass/GlobalMixins.module.scss';
@import '../../../../Sass/GlobalFunctions.module.scss';

.DrawerToggle {
    width: 40px;
    height: 100%;
    display: flex;
    flex-grow: 10;
    flex-flow: column;
    justify-content: space-around;
    align-self: flex-end;
    align-items: flex-end;
    padding: 10px 0;
    margin-right: 5%;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    min-width: 20%;
    max-width: 20%;
    height: 3px;
    margin: 2%;
    background-color: f_color(third);
}

@include desktop
{
    .hidden{
        display:none;
    }
}