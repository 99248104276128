body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #cecece;
  width: 100%;
  height: 100%;
}

code {
  font-family: 'Raleway', sans-serif;
}