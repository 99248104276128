@import '../../../Sass/GlobalFunctions.module.scss';

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 150;
    left: 0;
    top: 0;
    background-color: transparentize(f_color(primary), $amount: 0.05);
}