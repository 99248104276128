@import '../../../Sass/GlobalMixins.module.scss';

.NavigationItems {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
}

.NavigationButtons {
    display: flex;
    min-width: auto;
    justify-content: flex-start;
    flex-direction: column;
}

@include desktop
{
    .NavigationItems {
        flex-direction: row;
        min-height: auto;
    }
    
    .NavigationButtons {
        flex-direction: row;
        justify-content: flex-end;
    }
    
    .LanguageButtons {
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 0;
    }
}